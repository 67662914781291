<template>
	<div class="div_all">
		<el-form :inline="true" :model="callParam" class="demo-form-inline head-form" size="medium">
			<el-form-item label="通话服务器地址" prop="url">
				<el-input v-model="callParam.url" placeholder="请输入通话服务地址" clearable :style="{width: '100%'}">
				</el-input>
			</el-form-item>

			<el-form-item label="号码" prop="number">
				<el-input v-model="callParam.number" placeholder="请输入通话号码" clearable :style="{width: '100%'}">
				</el-input>
			</el-form-item>

			<el-form-item label="密码" prop="pwd">
				<el-input v-model="callParam.pwd" placeholder="请输入通话密码" clearable :style="{width: '100%'}">
				</el-input>
			</el-form-item>

			<el-form-item>
				<el-button class="search-btn" @click="register()" icon="el-icon-search" size="medium">注册</el-button>
			</el-form-item>
		</el-form>

		<div
			style="width: 424px; height: 324px;background-color: #333333; border: 2px solid blue; padding:0px; margin-top: 4px;">
			<video id="video" width="420px" height="320px" autoplay></video>
			<audio id="audio" controls></audio>
		</div>
	</div>
</template>

<script>
	import JsSIP from 'jssip'
	import axios from 'axios'

	export default {
		data() {
			return {
				searchParam: {
					time: null,
					module: '',
					userName: '',
					pageNum: 1,
					pageSize: 10,
					total: 0
				},
				pageData: [],
				callParam: {
					url: '47.94.104.253:5060',
					number: '13041143614',
					pwd: 'j82OZvhf',
				}, //保存通话相关数据
			};
		},
		mounted() {},
		methods: {
			/**
			 * 通话方法
			 */
			register() {
				var socket = new JsSIP.WebSocketInterface("ws://" + this.callParam.url);

				console.log("socket", socket)
				var configuration = {
					sockets: [socket],
					uri: 'sip:' + this.callParam.number + '@47.94.104.253',
					password: this.callParam.pwd,
					register: true,
					session_timers: false
				};

				var ua = new JsSIP.UA(configuration);
				ua.start();

				ua.on('connected', (e) => console.log('[SIP Phone] : Connected (On Call)', e));
				ua.on('registered', (e) => {
					// console.log('[SIP Phone] : Registered (On Call)', e);
					console.log('账号注册响应:::', e.response)
				});
				ua.on('registrationFailed', (e) => console.log('[SIP Phone] : Registration Failed (On Call)', e));

				// 注册打电话的回调事件
				var eventHandlers = {
					'progress': function(e) {
						console.log('call is in progress');
					},
					'failed': function(e) {
						var res = JSON.stringify(e);

						console.log('call failed with cause: ', res.message);
						console.log('call failed with cause: ', res);
					},
					'ended': function(e) {
						console.log('call ended with cause: ' + e);
					},
					'confirmed': function(e) {
						console.log('call confirmed');
					}
				};

				var options = {
					'eventHandlers': eventHandlers,
					'mediaConstraints': { 'audio': true, 'video': true }
				};

				//拨打电话
				var session = ua.call('sip:1019@192.168.0.11', options);
				console.log("session:::", session)
			},

		}

	};
</script>